import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { Col, Layout, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import BannerLogo from '../../common/icons/banner-icon.svg';

import './404.scss';

const NotFoundPage = () => {
  const {
    pageNotFound: { title, text, homePageLinkText, ctaLink, logoAltText },
    homepage: { url },
  } = useStaticQuery(graphql`
    query {
      pageNotFound {
        title
        text
        homePageLinkText
        ctaLink {
          url
          name
          target
        }
        logoAltText
      }
      homepage {
        url
      }
    }
  `);

  return (
    <Layout>
      <section className="error-page">
        <Row>
          <Col sm="12" md="12" xl="12" className="error-page__inner col--no-gutters">
            <h1 className="error-page__title">{title}</h1>
            <h2 className="error-page__text h5">{text}</h2>
            <div className="error-page__btns-wrap">
              <Link to={url} className="btn btn--white error-page__home-btn">
                {homePageLinkText}
              </Link>
              <LinkItem
                link={ctaLink}
                linkClass="btn btn--outline error-page__sitemap-btn"
                titleClass="error-page__btn-text"
                hasIcon={false}
                isLink
                showTitle
              />
            </div>
          </Col>
        </Row>
        <div className="error-page__logo-wrap">
          <BannerLogo className="error-page__logo" alt={logoAltText} />
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
